import Wix from '../services/wix-sdk-polyfill';

export const LOGOUT_MEMBER_SUCCESS = 'member/LOGOUT_SUCCESS';

export const logoutMember = () => {
  Wix.logOutCurrentMember();
  return {
    type: LOGOUT_MEMBER_SUCCESS,
  };
};

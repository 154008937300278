import uri from 'urijs';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePosts } from '../services/post-utils';
import {
  USER_ID_TYPE_OWNER,
  USER_ID_TYPE_SITE_MEMBER,
} from '@wix/communities-blog-client-common/dist/src/constants/user-id-types';
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { POSTS_PER_PAGE } from '@wix/communities-blog-client-common/dist/src/constants/pagination';
import { ENTITY_TYPE_POSTS } from '@wix/communities-blog-client-common/dist/src/constants/entity-types';

export const FETCH_USER_POSTS_REQUEST = 'userPosts/FETCH_REQUEST';
export const FETCH_USER_POSTS_SUCCESS = 'userPosts/FETCH_SUCCESS';
export const FETCH_USER_POSTS_FAILURE = 'userPosts/FETCH_FAILURE';

export const fetchProfilePostsRequest = createAction(FETCH_USER_POSTS_REQUEST);
export const fetchProfilePostsSuccess = createAction(
  FETCH_USER_POSTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchProfilePostsFailure = createAction(FETCH_USER_POSTS_FAILURE);

function fetchPosts({
  userIdType = USER_ID_TYPE_OWNER,
  id,
  entityType = ENTITY_TYPE_POSTS,
  status,
  page = 1,
  pageSize = POSTS_PER_PAGE,
  fixedPageSize,
}) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchProfilePostsRequest({ userIdType, id, page, status, entityType }));

    const _pageSize = fixedPageSize || pageSize;
    const params = {
      ...buildPaginationRequestParams(page, _pageSize),
      status,
    };

    const promise = request(uri(`/_api/posts/${userIdType}/${id}`).query(params), { parseHeaders: true });

    return promise
      .then(({ body = [], headers = {} }) =>
        dispatch(
          fetchProfilePostsSuccess(normalizePosts({ posts: body, blogCategoryIds: getCategoryIds(getState()) }), {
            page,
            entityCount: getTotalResults(headers),
            entityType,
            status,
            pageSize: _pageSize,
          }),
        ),
      )
      .catch(() => dispatch(fetchProfilePostsFailure({ userIdType, id, page, status, entityType })))
      .then(() => promise);
  };
}

export const fetchBySiteMemberId = ({ status, siteMemberId, page, pageSize, entityType }) =>
  fetchPosts({
    userIdType: USER_ID_TYPE_SITE_MEMBER,
    id: siteMemberId,
    status,
    page,
    pageSize,
    entityType,
  });

export const fetchDraftsBySiteMemberId = params => fetchBySiteMemberId({ status: POST_STATUS.unpublished, ...params });
export const fetchPostsBySiteMemberId = params => fetchBySiteMemberId({ status: POST_STATUS.published, ...params });

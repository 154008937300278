import { sortBy, chain, find, findIndex, filter } from 'lodash';
import { FETCH_CATEGORIES_SUCCESS } from './fetch-categories';
import { CREATE_CATEGORY_SUCCESS } from './create-category';
import { DELETE_CATEGORY_SUCCESS } from './delete-category';
import { UPDATE_CATEGORY_SUCCESS } from './update-category';
import { CHANGE_CATEGORY_RANK } from './change-category-rank';
import { UPDATE_CATEGORY_RANKS_SUCCESS } from './update-category-ranks';
import { SET_CATEGORIES } from './set-categories';
import { ADD_CATEGORY } from './add-category';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from './subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from './unsubscribe';
import { addOrReplaceByKeys } from '../../services/array';

const initialState = [];
const SORT_BY = 'rank';

const categories = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_CATEGORIES_SUCCESS:
      return sortBy(payload, SORT_BY);

    case ADD_CATEGORY:
    case CREATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_SUCCESS:
      return sortBy(addOrReplaceByKeys(state, payload, '_id'), SORT_BY);

    case CHANGE_CATEGORY_RANK: {
      const { rank, categoryId: _id } = payload;
      return updateRanks(state, [{ _id, rank }]);
    }
    case UPDATE_CATEGORY_RANKS_SUCCESS:
      return updateRanks(state, payload);

    case DELETE_CATEGORY_SUCCESS:
      return state.filter(category => category._id !== payload);

    case SET_CATEGORIES:
      return [...payload];

    case SUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, true);

    case SUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_REQUEST:
      return setIsSubscribed(state, payload, false);

    case UNSUBSCRIBE_FAILURE:
      return setIsSubscribed(state, payload, true);

    default:
      return state;
  }
};

function updateRanks(state, rankChanges) {
  const changes = sortBy(rankChanges, SORT_BY);
  const ids = changes.map(x => x._id);
  const tempState = filter(state, category => !ids.includes(category._id));
  changes.forEach(x =>
    tempState.splice(
      x.rank,
      0,
      find(state, category => category._id === x._id),
    ),
  );
  return chain(tempState)
    .map((category, rank) => ({
      ...category,
      rank,
    }))
    .sortBy(SORT_BY)
    .value();
}

function setIsSubscribed(state, { type, _id }, isSubscribed) {
  if (type !== 'category') {
    return state;
  }

  const index = findIndex(state, category => category._id === _id);
  return [
    ...state.slice(0, index),
    {
      ...state[index],
      isSubscribed,
    },
    ...state.slice(index + 1),
  ];
}

export default categories;

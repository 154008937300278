import UrlPattern from 'url-pattern';

const FALLBACK_ROUTE = '*';

const decodeParams = match =>
  Object.keys(match).reduce((acc, cur) => {
    acc[cur] = decodeURIComponent(match[cur]);
    return acc;
  }, {});

export class Router {
  static resultIdentifier = '@@RouterResult';

  constructor() {
    this.routes = new Map();
    this.matchListeners = [];
  }

  add = (route, callback) => {
    this.routes.set(route, callback);
  };

  fallback = redirect => {
    this.routes.set(FALLBACK_ROUTE, { route: redirect, callback: this.routes.get(redirect) });
  };

  triggerMatch = result => this.matchListeners.forEach(cb => cb(result));

  match = (pathname, prevMatches = []) =>
    new Promise((resolve, reject) => {
      if (prevMatches.length >= 5) {
        return reject('too many redirects');
      }

      let pathToMatch = pathname;
      if (pathToMatch === '') {
        pathToMatch = '/';
      }
      if (pathToMatch !== '/') {
        pathToMatch = pathToMatch.replace(/\/+$/g, '');
      }

      const entries = this.routes.entries();
      let entry = entries.next();
      let match;

      while (!entry.done && !match) {
        let [route, callback] = entry.value;
        const options = { segmentValueCharset: 'a-zA-Z0-9-_~ %.' };
        const pattern = new UrlPattern(route, options);
        match = pattern.match(pathToMatch);

        if (match) {
          if (route === FALLBACK_ROUTE) {
            ({ route, callback } = this.routes.get(FALLBACK_ROUTE));
          }

          const result = {
            pathname,
            route,
            params: decodeParams(match),
            prevMatches,
            [Router.resultIdentifier]: true,
          };

          this.triggerMatch(result);

          if (callback) {
            Promise.resolve(callback(result, pathname => this.match(pathname, prevMatches.concat(result))))
              .then(val => {
                resolve(val && val[Router.resultIdentifier] ? val : result);
              })
              .catch(reject);
          } else {
            resolve(result);
          }
        }

        entry = entries.next();
      }

      if (!match) {
        reject('failed to match route');
      }
    });

  onMatch = callback => this.matchListeners.push(callback);
}

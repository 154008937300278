import { isDraftCreated, isPublished } from '../event-helpers';
import { SAVE_POST_DRAFT_SUCCESS } from '../../actions/save-post-draft';

const handler = action => {
  const { post } = action.payload;
  if (isDraftCreated(post)) {
    return {
      evid: 205,
      is_published: isPublished(post),
      post_id: post._id,
      eventMeta: {
        description: 'post draft created',
      },
    };
  }
};

export const uouSavePostDraftSuccessEvent = { [SAVE_POST_DRAFT_SUCCESS]: handler };

import { compact } from 'lodash';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { debounceActions, throttleActions } from '../../common/middleware/redux-middlewares';

import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import loadingBarMiddleware from '../../common/store/loading-bar/loading-bar-middleware';
import createShowMessage from '../../common/messages/framework/create-show-message';
import {
  createDuplexerConnection,
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../../common/middleware/duplexer-middleware';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import messageResolvers from '../components/messages/message-resolvers';
import { appUouBiMiddleware, appUsersBiMiddleware } from '../../common/bi-events/init-middleware';
import { eventMap } from '../bi-events/uou-events';
import { usersEventMap } from '../bi-events/users-events';
import { getInstance, createRequestWithBaseUrl, appendOriginInBackend } from '../../common/controller/helpers';
import { createPostChannelMiddleware } from '../middleware/post-channel-middleware';

export function createReduxStore({
  reducers,
  appParams,
  wixCodeApi,
  compId,
  fedopsLogger,
  platformAPIs,
  getRouter,
  isSSR,
  sentry,
  isEditor,
  isPreview,
}) {
  const p = {};
  const createRequest = createRequestWithBaseUrl({ wixCodeApi, getStore: () => p.store });
  const {
    apiBaseUrlClient,
    apiPlatformizedBaseUrlClient,
    duplexerUrl,
    apiExperimentsBaseUrlClient,
    disableDuplexerForAll,
    disableDuplexerForInstanceIds,
    apiPaywallBaseUrl,
  } = appParams.baseUrls;

  const platformizedRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPlatformizedBaseUrlClient });
  const platformizedRequest = createRequest(platformizedRequestUrl);

  const experimentsRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient });
  const experimentsRequest = createRequest(experimentsRequestUrl);

  const paywallRequestUrl = appendOriginInBackend({ wixCodeApi, baseUrl: apiPaywallBaseUrl });
  const paywallRequest = createRequest(paywallRequestUrl);

  const blogApiBaseUrl = isEditor || isPreview ? apiBaseUrlClient : `${wixCodeApi.location.baseUrl}${apiBaseUrlClient}`;
  const request = createRequest(blogApiBaseUrl);

  const isSeo = wixCodeApi.seo.isInSEO();

  const disableDuplexer = (disableDuplexerForInstanceIds || '').split(',').includes(appParams.instanceId);
  const useDuplexer = !Boolean(disableDuplexerForAll) && !isSSR && !isEditor && !isSeo && !disableDuplexer;

  const middleware = compact([
    throttleActions(),
    debounceActions(),
    thunk.withExtraArgument({
      request,
      platformizedRequest,
      experimentsRequest,
      paywallRequest,
      wixCodeApi,
      compId,
      appParams,
      fedopsLogger,
      getRouter,
    }),
    createFastFormSubmitMiddleware({}),
    // createRouterMiddleware(getRouter),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
    // createInteractionsMiddleware(fedopsLogger, {
    //   [CREATE_POST_SUCCESS]: CREATE_POST,
    //   [UPDATE_POST_SUCCESS]: UPDATE_POST,
    //   [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
    //   [DELETE_POST_SUCCESS]: DELETE_POST,
    //   [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
    //   [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
    //   [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
    //   [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
    // }),
    isEditor
      ? appUsersBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap: usersEventMap })
      : appUouBiMiddleware({ platformAPIs, instanceId: appParams.instanceId, eventMap }),
    loadingBarMiddleware(),
    useDuplexer &&
      createDuplexerMiddleware({
        duplexerConnection: createDuplexerConnection({
          duplexerUrl,
          blogApiBaseUrl,
          instance: getInstance(wixCodeApi)(),
        }),
        eventHandlers: socketEventHandlers,
        instanceId: appParams.instanceId,
        captureMessage: (message, extra) => sentry.captureException(message, { extra }),
      }),
    useDuplexer && createPostChannelMiddleware(),
  ]);

  p.store = createStore(reducers, undefined, applyMiddleware(...middleware));

  return p.store;
}

import { DEBOUNCE_CANCEL } from '../../common/middleware/redux-middlewares';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { shouldSearchByHashtag } from '../../common/services/search-utils';
import { extractHashtagsFromText } from '../../common/services/hashtag-utils';
import { normalizePostEntities } from '../../common/services/post-utils';

export const FETCH_SEARCH_REQUEST = 'search/FETCH_REQUEST';
export const FETCH_SEARCH_SUCCESS = 'search/FETCH_SUCCESS';
export const FETCH_SEARCH_FAILURE = 'search/FETCH_FAILURE';
export const FETCH_SEARCH_DEBOUNCED = 'search/FETCH_DEBOUNCED';

export const fetchSearchRequest = createAction(FETCH_SEARCH_REQUEST);
export const fetchSearchSuccess = createAction(FETCH_SEARCH_SUCCESS);
export const fetchSearchFailure = createAction(FETCH_SEARCH_FAILURE);

const DEBOUNCE_WAIT = 300;

export default function fetchSearch(query) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchSearchRequest());

    const { path /* , meteredPathKey*/ } = shouldSearchByHashtag(query)
      ? {
          path: `/_api/search/hashtags?q=${extractHashtagsFromText(query).join(',')}`,
          // meteredPathKey: '/_api/search/hashtags#q',
        }
      : {
          path: `/_api/search?q=${encodeURIComponent(query)}`,
          // meteredPathKey: '/_api/search#q',
        };

    // const promise = request.metered(meteredPathKey)(path);
    const promise = request(path);

    return promise
      .then(results =>
        dispatch(fetchSearchSuccess({ ...results, posts: normalizePostEntities(results.posts, getState()) })),
      )
      .catch(() => dispatch(fetchSearchFailure()))
      .then(() => promise);
  };
}

export function fetchSearchDebounced(query, debounce = DEBOUNCE_WAIT) {
  const action = fetchSearch(query);
  action.type = FETCH_SEARCH_DEBOUNCED;
  action.meta = { debounce };
  return action;
}

export function fetchSearchWithCancel({ query, debounce, minQueryLength }) {
  if (query.length >= minQueryLength) {
    return fetchSearchDebounced(query, debounce);
  }
  return {
    type: DEBOUNCE_CANCEL,
    payload: {
      type: FETCH_SEARCH_DEBOUNCED,
    },
  };
}

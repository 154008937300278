import { CLEAR_MEMBERS } from '../clear-members';
import { FETCH_MEMBERS_SUCCESS } from '../fetch-members';

export default (state = { count: 0 }, action = {}) => {
  switch (action.type) {
    case CLEAR_MEMBERS:
      return { count: 0 };
    case FETCH_MEMBERS_SUCCESS:
      return { count: action.meta.count };
    default:
      return state;
  }
};

import { map, pick } from 'lodash';

export const UPDATE_CATEGORY_RANKS_REQUEST = 'categories/UPDATE_RANKS_REQUEST';
export const UPDATE_CATEGORY_RANKS_SUCCESS = 'categories/UPDATE_RANKS_SUCCESS';
export const UPDATE_CATEGORY_RANKS_FAILURE = 'categories/UPDATE_RANKS_FAILURE';

export const updateCategoryRanksRequest = () => ({ type: UPDATE_CATEGORY_RANKS_REQUEST });
export const updateCategoryRanksSuccess = idsWithRanks => ({
  type: UPDATE_CATEGORY_RANKS_SUCCESS,
  payload: idsWithRanks,
});
export const updateCategoryRanksFailure = () => ({ type: UPDATE_CATEGORY_RANKS_FAILURE });

export const updateCategoryRanks = idsWithRanks => (dispatch, getState, { request }) => {
  dispatch(updateCategoryRanksRequest());
  const promise = request.patch('/_api/categories', idsWithRanks);
  return promise
    .then(() => dispatch(updateCategoryRanksSuccess(idsWithRanks)))
    .catch(() => dispatch(updateCategoryRanksFailure()))
    .then(() => promise);
};

export const updateCategoryRanksByCategories = categories =>
  updateCategoryRanks(map(categories, category => pick(category, ['_id', 'rank'])));

export const updateCategoryRanksByOrderedIds = ids =>
  updateCategoryRanks(map(ids, (id, index) => ({ _id: id, rank: index })));

import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import uri from 'urijs';
import { buildPaginationRequestParams, getTotalResults } from '../../services/pagination';
import { COMMENTS_PER_PAGE } from '@wix/communities-blog-client-common/dist/src/constants/pagination';
import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';

export const FETCH_COMMENTS_REQUEST = 'comments/FETCH_REQUEST';
export const FETCH_COMMENTS_SUCCESS = 'comments/FETCH_SUCCESS';
export const FETCH_COMMENTS_FAILURE = 'comments/FETCH_FAILURE';

export const fetchCommentsRequest = createAction(FETCH_COMMENTS_REQUEST);
export const fetchCommentsSuccess = createAction(
  FETCH_COMMENTS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchCommentsFailure = createAction(FETCH_COMMENTS_FAILURE);

export const fetchComments = ({ postId, page = 1, pageSize = COMMENTS_PER_PAGE }) => {
  return (dispatch, _getState, { request }) => {
    dispatch(fetchCommentsRequest({ postId, page }));

    const params = buildPaginationRequestParams(page, pageSize);
    const promise = request(uri(`/_api/posts/${postId}/comments`).query(params), { parseHeaders: true });
    return promise
      .then(({ body = [], headers = {} }) => {
        const payload = {
          comments: body,
          postId,
        };
        const meta = {
          page,
          entityCount: getTotalResults(headers),
          pageSize,
        };
        const action = fetchCommentsSuccess(payload, meta);
        return dispatch(action);
      })
      .catch(() => dispatch(fetchCommentsFailure({ postId, page })))
      .then(() => promise);
  };
};

export const fetchCommentsPromisified = createPromisifiedAction(
  fetchComments,
  () => null,
  response => response.status,
);

import _ from 'lodash';

export const CREATE_USER_REQUEST = 'user/CREATE_REQUEST';
export const CREATE_USER_SUCCESS = 'user/CREATE_SUCCESS';
export const CREATE_USER_FAILURE = 'user/CREATE_FAILURE';

export const createUserRequest = () => ({ type: CREATE_USER_REQUEST });
export const createUserSuccess = payload => ({ type: CREATE_USER_SUCCESS, payload });
export const createUserFailure = () => ({ type: CREATE_USER_FAILURE });

export function createUser(user) {
  return (dispatch, getState, { request }) => {
    dispatch(createUserRequest());

    const data = _.omitBy(user, value => value === null);
    const promise = request.post(`/_api/users/`, data);
    return promise
      .then(user => dispatch(createUserSuccess(user)))
      .catch(() => dispatch(createUserFailure()))
      .then(() => promise);
  };
}

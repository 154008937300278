const STATE_BOX = '@stateBox';
const MEDIA_BOX = '@mediaBox';
const ARCHIVE_LIST = '@archiveList';
const LIST_ITEM = '@listItem';

export const itemSelectors = {
  STATE_BOX,
  MEDIA_BOX,
  ARCHIVE_LIST,
  LIST_ITEM,
};

const MONTHS_RANGE_TYPE = 'monthsRangeType';
const SHOW_POSTS_NUMBER = 'showPostsNumber';

export const propertyKeys = {
  MONTHS_RANGE_TYPE,
  SHOW_POSTS_NUMBER,
};

export const MONTHS_RANGE_TYPE_ALL = '1';
export const MONTHS_RANGE_TYPE_LAST_12 = '2';
export const MONTHS_RANGE_TYPE_LAST_24 = '3';

export const MONTHS_RANGE_ALL = null; // todo
export const MONTHS_RANGE_12 = 12;
export const MONTHS_RANGE_24 = 24;

export const DEFAULT_MONTHS_RANGE_TYPE = MONTHS_RANGE_TYPE_ALL;
export const DEFAULT_SHOW_POSTS_NUMBER = false;

import { isArray, isNumber, isEmpty } from 'lodash';
import { categoryMapper } from '@wix/communities-blog-wix-code-common';
import urlService from './url';

const getAllPostsCount = categories => {
  if (!isArray(categories) || isEmpty(categories) || !categories.every(category => isNumber(category.postCount))) {
    return;
  }

  return categories.reduce((acc, category) => (acc = acc + category.postCount), 0);
};

const getCategories = ({ instance, wixCodeApi }) =>
  fetch(`${urlService.getAPIUrl(wixCodeApi)}/categories`, {
    headers: {
      Accept: 'application/json',
      Authorization: instance,
    },
  })
    .then(resp => (resp.ok ? resp.json() : {}))
    .then(({ categories }) => (categories ? categories.map(categoryMapper.map) : [{}]))
    .catch(() => undefined);

export default {
  getCategories,
  getAllPostsCount,
};

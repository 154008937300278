import { map } from 'lodash';

import { FETCH_RECENT_POSTS_SUCCESS } from '../../post-page/actions/fetch-recent-posts';

export default function recentPostsIdsReducer(state = {}, { type, payload, meta } = {}) {
  switch (type) {
    case FETCH_RECENT_POSTS_SUCCESS:
      return { ...state, [meta.postId]: map(payload, post => post._id) };
    default:
      return state;
  }
}

import { isEmpty, escape } from 'lodash';
import { TRANSFORM_TYPE_FIT } from '@wix/communities-blog-universal/dist/src/constants/image';
import { getPostCover } from './get-post-cover';

import { getImageUrl as _getImageUrl } from '@wix/communities-blog-universal/dist/src/utils/media/image';
import { getCanonicalPostUrl } from './post-utils';
import { getContentText } from './content-text';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common/dist/src/constants/post';
import { getCategoryUrl } from './get-category-url';

const getDescription = post => {
  const text = post.excerpt || getContentText(post.content);
  return text.slice(0, POST_EXCERPT_MAX_LENGTH);
};

const getImageSchema = (image, getImageUrl) => ({
  '@type': 'ImageObject',
  height: image.height,
  width: image.width,
  url: getImageUrl(image, 1000, 1000, 80, TRANSFORM_TYPE_FIT, 'jpg'),
});

export const getAuthorSchema = ({ name, image }, getImageUrl) => {
  return {
    '@type': 'Person',
    name,
    ...(image
      ? {
          image: {
            '@type': 'ImageObject',
            url: typeof image === 'string' ? image : getImageUrl(image, 1000, 1000, 80, TRANSFORM_TYPE_FIT, 'jpg'),
          },
        }
      : {}),
  };
};

export const getArticleSection = (post, categoryMap) =>
  post.categoryIds
    .map(id => categoryMap[id])
    .filter(Boolean)
    .map(({ label }) => label)
    .join(', ');

export const getPostSchema = ({ post, categoryMap, postPageSectionUrl, publisher, getImageUrl }) => {
  if (!publisher || isEmpty(post) || !post.owner) {
    return undefined;
  }
  const { shouldRender, imageMetadata } = getPostCover(post);
  const postUrl = getCanonicalPostUrl({ post, postPageSectionUrl });
  const description = getDescription(post);
  const articleSection = getArticleSection(post, categoryMap);

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    author: getAuthorSchema(post.owner, getImageUrl),
    articleSection,
    dateModified: post.lastPublishedDate,
    datePublished: post.firstPublishedDate,
    headline: escape(post.title),
    mainEntityOfPage: { '@id': postUrl, '@type': 'WebPage' },
    description: escape(description),
    url: postUrl,
    image: shouldRender && imageMetadata ? getImageSchema(imageMetadata, getImageUrl) : {},
    publisher,
  };
};

const getPostsSchema = (appConfig, posts, categoryMap) => {
  const { postPageSectionUrl, publisher } = appConfig;
  const getImageUrl = (image, maxWidth, maxHeight, quality, type, format, blur) =>
    _getImageUrl(appConfig.imageHost)(image, { maxWidth, maxHeight, quality, type, format, blur });
  return posts.map(post => getPostSchema({ post, categoryMap, postPageSectionUrl, publisher, getImageUrl }));
};

export const getCategorySchema = (appConfig, category, posts, categoryMap) => {
  const { sectionUrl, categoryPath } = appConfig;

  return {
    '@context': 'https://schema.org',
    '@type': 'CollectionPage',
    url: getCategoryUrl(sectionUrl, categoryPath, category.slug),
    headline: escape(category.label),
    description: escape(category.description),
    hasPart: getPostsSchema(appConfig, posts, categoryMap),
    sameAs: [],
  };
};

export const getWebsiteJSONSchema = sectionUrl => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    '@id': '#website',
    url: sectionUrl,
  };
};

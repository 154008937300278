import { getCategoryIds } from '../../common/selectors/categories-selectors';
import { normalizePost } from '../../common/services/post-utils';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';

export const DISABLE_POST_COMMENTS_REQUEST = 'post/DISABLE_COMMENTS_REQUEST';
export const DISABLE_POST_COMMENTS_SUCCESS = 'post/DISABLE_COMMENTS_SUCCESS';
export const DISABLE_POST_COMMENTS_FAILURE = 'post/DISABLE_COMMENTS_FAILURE';

export function disablePostComments(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: DISABLE_POST_COMMENTS_REQUEST,
    });

    const promise = request.post(`/_api/posts/${id}/disableComments`);

    return promise
      .then(post =>
        dispatch({
          type: DISABLE_POST_COMMENTS_SUCCESS,
          payload: normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) }),
        }),
      )
      .catch(() =>
        dispatch({
          type: DISABLE_POST_COMMENTS_FAILURE,
        }),
      )
      .then(() => promise);
  };
}

export const disablePostCommentsPromisified = createPromisifiedAction(
  disablePostComments,
  data => data,
  response => response.status,
);

import { combineReducers } from 'redux';
import currentUser from './current-user';
import isAuthenticating from './is-authenticating';
import isAuthenticated from './is-authenticated';

export default combineReducers({
  currentUser,
  isAuthenticated,
  isAuthenticating,
});

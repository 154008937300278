import { getCurrentMatchPathname } from '../router/router-selectors';

// This middleware handles an action dispatched from the feed app settings when
// switching between all-posts and categories tabs. It triggers the feed router
// which updates the view accordingly.
export default function createRouterMiddleware(getRouter) {
  return store => next => action => {
    switch (action.type) {
      case '@@router/CALL_HISTORY_METHOD':
        if (action.payload.method === 'push') {
          const router = getRouter();
          const currentPathname = getCurrentMatchPathname(store.getState());
          const pathname = action.payload.args[0];
          if (router && currentPathname !== pathname) {
            router.match(pathname);
          }
        }
        break;

      default:
        break;
    }

    next(action);
  };
}

import { SET_IS_POST_IN_PREVIEW } from '../actions/set-is-post-in-preview';

const isPostInPreview = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_POST_IN_PREVIEW:
      return payload;
    default:
      return state;
  }
};

export default isPostInPreview;

import { CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from '../../../actions/create-user';
import { FETCH_CURRENT_USER_SUCCESS } from '../../../actions/fetch-current-user';

export default (state = false, { type }) => {
  switch (type) {
    case CREATE_USER_REQUEST:
    case FETCH_CURRENT_USER_SUCCESS:
      return true;
    case CREATE_USER_SUCCESS:
    case CREATE_USER_FAILURE:
      return false;
    default:
      return state;
  }
};

import { truncate } from 'lodash';
import {
  POST_ORDER_TYPE_PUBLISH_DATE,
  POST_ORDER_TYPE_LIKE_COUNT,
  POST_ORDER_TYPE_VIEW_COUNT,
} from '../constants/post-widget';

export const applyCharLimitEllipsis = (str, limit) => truncate(str, { length: limit, separator: '...' });

export const getDateString = lastPublishedDate =>
  new Date(lastPublishedDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

export const secondsToMiliseconds = seconds => seconds * 1000;

export const getPostOrderTypeQuery = postOrderType => {
  switch (postOrderType) {
    case POST_ORDER_TYPE_PUBLISH_DATE:
      return 'publishedDate:desc';
    case POST_ORDER_TYPE_VIEW_COUNT:
      return 'viewCount:desc';
    case POST_ORDER_TYPE_LIKE_COUNT:
      return 'likeCount:desc';
    default:
      return null;
  }
};

export default {
  getDateString,
  applyCharLimitEllipsis,
  getPostOrderTypeQuery,
  secondsToMiliseconds,
};

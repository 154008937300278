import { omit } from 'lodash';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const SET_BLOG_DATA = 'appData/SET';

export const UPDATE_BLOG_DATA_REQUEST = 'appData/UPDATE_REQUEST';
export const UPDATE_BLOG_DATA_SUCCESS = 'appData/UPDATE_SUCCESS';
export const UPDATE_BLOG_DATA_FAILURE = 'appData/UPDATE_FAILURE';

export const FETCH_BLOG_DATA_REQUEST = 'appData/FETCH_REQUEST';
export const FETCH_BLOG_DATA_SUCCESS = 'appData/FETCH_SUCCESS';
export const FETCH_BLOG_DATA_FAILURE = 'appData/FETCH_FAILURE';

export const fetchAppDataRequest = () => ({ type: FETCH_BLOG_DATA_REQUEST });
export const fetchAppDataSuccess = payload => ({ type: FETCH_BLOG_DATA_SUCCESS, payload });
export const fetchAppDataFailure = () => ({ type: FETCH_BLOG_DATA_FAILURE });

export const updateAppDataRequest = () => ({ type: UPDATE_BLOG_DATA_REQUEST });
export const updateAppDataSuccess = payload => ({ type: UPDATE_BLOG_DATA_SUCCESS, payload });
export const updateAppDataFailure = () => ({ type: UPDATE_BLOG_DATA_FAILURE });

export const setAppData = createAction(SET_BLOG_DATA);

export function fetchAppData(metaSiteId) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchAppDataRequest());

    const promise = request(`/_api/blogs/this?cacheId=${metaSiteId}`);
    return promise
      .then(
        appData => dispatch(fetchAppDataSuccess(appData)),
        () => dispatch(fetchAppDataFailure()),
      )
      .then(() => promise);
  };
}

export function updateAppData(data = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(updateAppDataRequest());
    const promise = request.patch('/_api/blogs/this', omit(data, ['_id', 'communityId']));

    return promise
      .then(post => dispatch(updateAppDataSuccess(post)))
      .catch(() => dispatch(updateAppDataFailure()))
      .then(() => promise);
  };
}

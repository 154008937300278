import { get } from 'lodash';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import { EXPERIMENT_ARCHIVE_MONTHS_LIMIT } from '@wix/communities-blog-experiments';

export const FETCH_POST_STATS_BY_DATE_REQUEST = 'postStatsByDate/FETCH_REQUEST';
export const FETCH_POST_STATS_BY_DATE_SUCCESS = 'postStatsByDate/FETCH_SUCCESS';
export const FETCH_POST_STATS_BY_DATE_FAILURE = 'postStatsByDate/FETCH_FAILURE';

export const fetchPostStatsByDateRequest = createAction(FETCH_POST_STATS_BY_DATE_REQUEST);
export const fetchPostStatsByDateSuccess = createAction(FETCH_POST_STATS_BY_DATE_SUCCESS);
export const fetchPostStatsByDateFailure = createAction(FETCH_POST_STATS_BY_DATE_FAILURE);

export const fetchPostStatsByDate = months => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchPostStatsByDateRequest());

    const promise = isExperimentEnabled(getState(), EXPERIMENT_ARCHIVE_MONTHS_LIMIT)
      ? request.post(`/v2/stats/publications/count`, { order: 'NEWEST', months })
      : request.post(`/v2/stats/publications-count`, { rangeStart: '1970-01-01T00:00:00Z' });
    return promise
      .then(
        postStatsByDate => dispatch(fetchPostStatsByDateSuccess(get(postStatsByDate, 'stats', []))),
        () => dispatch(fetchPostStatsByDateFailure()),
      )
      .then(() => Promise.resolve());
  };
};

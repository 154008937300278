import { SET_USER } from '../../actions/set-user';
import { NEW_NOTIFICATION } from '../../actions/user';
import { ENABLE_NOTIFICATION_COUNT_CHANGE } from '../../actions/enable-notifications-count-change';
import { FETCH_USER_STATS_SUCCESS } from './user-stats-actions';

export const INITIAL_STATE = { notificationCount: undefined, isNotificationCountUpdateAllowed: true };

const userStatsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case FETCH_USER_STATS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        notificationCount: state.isNotificationCountUpdateAllowed
          ? action.payload.notificationCount
          : state.notificationCount,
      };
    case ENABLE_NOTIFICATION_COUNT_CHANGE:
      return { ...state, isNotificationCountUpdateAllowed: action.payload };
    case SET_USER:
      return state.isNotificationCountUpdateAllowed ? { ...state, notificationCount: undefined } : state;
    case NEW_NOTIFICATION:
      return state.isNotificationCountUpdateAllowed && state.notificationCount !== undefined
        ? { ...state, notificationCount: state.notificationCount + 1 }
        : state;
    default:
      return state;
  }
};

export default userStatsReducer;

import { SET_LOCALE } from './locale-actions';

export default function locale(state = 'en', action) {
  switch (action.type) {
    case SET_LOCALE:
      return action.payload;

    default:
      return state;
  }
}

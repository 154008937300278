export const DELETE_CATEGORY_REQUEST = 'category/DELETE_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'category/DELETE_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'category/DELETE_FAILURE';

const deleteCategoryRequest = () => ({ type: DELETE_CATEGORY_REQUEST });
const deleteCategorySuccess = payload => ({ type: DELETE_CATEGORY_SUCCESS, payload });
const deleteCategoryFailure = () => ({ type: DELETE_CATEGORY_FAILURE });

export function deleteCategory(categoryId) {
  return (dispatch, getState, { request }) => {
    dispatch(deleteCategoryRequest());

    const promise = request.delete(`/_api/categories/${categoryId}`);

    return promise
      .then(() => dispatch(deleteCategorySuccess(categoryId)))
      .catch(() => dispatch(deleteCategoryFailure()))
      .then(() => promise);
  };
}

import { reduce } from 'lodash';
import { ADD_USER } from '../../actions/add-user';
import { FETCH_USER_SUCCESS } from '../../actions/fetch-user';
import { FETCH_CURRENT_USER_SUCCESS } from '../../actions/fetch-current-user';
import { FETCH_MEMBERS_SUCCESS } from '../../actions/fetch-members';
import { CREATE_USER_SUCCESS } from '../../actions/create-user';

// eslint-disable-next-line complexity
const users = (state = {}, { payload, type } = {}) => {
  switch (type) {
    case ADD_USER:
    case FETCH_USER_SUCCESS:
    case FETCH_CURRENT_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
      return addUsers(state, [payload]);
    case FETCH_MEMBERS_SUCCESS:
      return addUsers(state, payload);
    default:
      return state;
  }
};

function addUsers(state, users) {
  return reduce(
    users,
    (acc, user) => ({
      ...acc,
      [user.siteMemberId]: {
        ...acc[user.siteMemberId],
        ...user,
      },
    }),
    { ...state },
  );
}

export default users;

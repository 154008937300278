import {
  POSTS_IMPORTED,
  POST_PUBLISHED,
  UPDATE_POST_COUNTERS,
  NEW_COMMENT,
} from '@wix/communities-blog-universal/src/constants/ws-events';
import { updatePostCounters } from '../../store/post-counters/post-counters-actions';
import { clearPosts } from '../../actions/clear-posts';
import { fetchComment } from '../../store/comments/fetch-comment';
import fetchFeedPosts from '../../actions/fetch-feed-posts';
import { fetchPost } from '../../actions/fetch-post';
import { isEditor } from '../../store/basic-params/basic-params-selectors';

export default {
  [NEW_COMMENT]: fetchComment,
  [POST_PUBLISHED]: ({ postId }) => fetchPost(postId),
  [UPDATE_POST_COUNTERS]: updatePostCounters,
  [POSTS_IMPORTED]: () => (dispatch, getState) => {
    if (isEditor(getState())) {
      dispatch(clearPosts());
      dispatch(fetchFeedPosts());
    }
  },
};

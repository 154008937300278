import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';
import { createCategory } from './create-category';

export const createCategoryFromLabel = menuLabel => createCategory({ menuLabel });

export const createCategoryFromLabelPromisified = createPromisifiedAction(
  createCategoryFromLabel,
  category => category,
  response => response.status,
);

import uri from 'urijs';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { buildPaginationRequestParams, getTotalResults } from '../services/pagination';

export const FETCH_MEMBERS_REQUEST = 'members/FETCH_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'members/FETCH_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'members/FETCH_FAILURE';
export const FETCH_MEMBERS_DEBOUNCED = 'members/FETCH_DEBOUNCED';

export const fetchMembersRequest = createAction(FETCH_MEMBERS_REQUEST);
export const fetchMembersSuccess = createAction(
  FETCH_MEMBERS_SUCCESS,
  payload => payload,
  (payload, meta) => meta,
);
export const fetchMembersFailure = createAction(FETCH_MEMBERS_FAILURE);

export function fetchMembers({ page, pageSize, ...params }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchMembersRequest());
    const url = uri('/_api/members').query({
      sort: 'postCount',
      ...params,
      ...buildPaginationRequestParams(page, pageSize),
    });
    const promise = request(url, {
      parseHeaders: true,
    });

    return promise
      .then(({ body, headers }) =>
        dispatch(
          fetchMembersSuccess(body, {
            count: getTotalResults(headers),
            page,
          }),
        ),
      )
      .catch(() => dispatch(fetchMembersFailure()))
      .then(() => promise);
  };
}

export const fetchMembersDebounced = (params, debounce = 300) => {
  const action = fetchMembers(params);
  action.type = FETCH_MEMBERS_DEBOUNCED;
  action.meta = { debounce };
  return action;
};

export const fetchMembersWithFakeLoading = (params, debounce = 300) => dispatch => {
  dispatch(fetchMembersRequest());
  dispatch(fetchMembersDebounced(params, debounce));
};

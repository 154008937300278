import { buildUrl } from '../services/video-embed';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const FETCH_OEMBED_REQUEST = 'oembed/REQUEST';
export const FETCH_OEMBED_SUCCESS = 'oembed/SUCCESS';
export const FETCH_OEMBED_FAILURE = 'oembed/FAILURE';

export const fetchOembedRequest = () => ({ type: FETCH_OEMBED_REQUEST });
export const fetchOembedSuccess = payload => ({ type: FETCH_OEMBED_SUCCESS, payload });
export const fetchOembedFailure = () => ({ type: FETCH_OEMBED_FAILURE });

export const fetchOembed = url => (dispatch, getState, { request }) => {
  const id = new Date().getTime();
  const videoUrl = buildUrl(url);

  dispatch(fetchOembedRequest());
  dispatch(setIsLoading('oembed', id, true));

  const promise = videoUrl ? request(`/_api/oembed?url=${videoUrl}`) : Promise.resolve(null);

  return promise
    .then(details => dispatch(fetchOembedSuccess(details)))
    .catch(() => dispatch(fetchOembedFailure()))
    .then(() => {
      const action = setIsLoading('oembed', id, false);
      action.meta = { debounce: 1 };
      dispatch(action);
    })
    .then(() => promise);
};

export const fetchOembedPromisified = createPromisifiedAction(
  fetchOembed,
  data => data,
  response => response.status,
);

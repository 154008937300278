import { findIndex } from 'lodash';
import { SEARCH_CLEAR_RESULTS, SEARCH } from '../actions/search';
import { FETCH_SEARCH_SUCCESS, FETCH_SEARCH_FAILURE } from '../actions/fetch-search';
import { SUBSCRIBE_REQUEST, SUBSCRIBE_FAILURE } from '../../common/actions/subscribe';
import { UNSUBSCRIBE_REQUEST, UNSUBSCRIBE_FAILURE } from '../../common/actions/unsubscribe';
import { PIN_POST_SUCCESS } from '../../common/actions/pin-post';
import { UNPIN_POST_SUCCESS } from '../../common/actions/unpin-post';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/enable-post-comments';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../../comments/actions/disable-post-comments';
import { DELETE_POST_SUCCESS } from '../../common/actions/delete-post';
import { DELETE_COMMENT_SUCCESS } from '../../common/store/comments/delete-comment';

const initialState = {
  lastSearchUuid: undefined,
  posts: [],
  members: [],
};

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      if (state._id === action.payload._id) {
        return { ...action.payload, type: 'post' };
      }
      return state;
    default:
      return state;
  }
};

const renameUsersKey = data => {
  const { users, ...result } = data;
  result.members = users;

  return result;
};

// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return { ...state, query: action.payload };
    case FETCH_SEARCH_SUCCESS:
      return { ...state, ...renameUsersKey(action.payload), posts: action.payload.posts };
    case FETCH_SEARCH_FAILURE:
    case SEARCH_CLEAR_RESULTS:
      return { ...state, posts: [], members: [] };
    case SUBSCRIBE_REQUEST: {
      const index = findIndex(state.members, ['_id', action.payload._id]);
      if (index > -1) {
        const member = state.members[index];
        return {
          ...state,
          members: [
            ...state.members.slice(0, index),
            { ...member, followerCount: member.followerCount + 1, isSubscribed: true },
            ...state.members.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case UNSUBSCRIBE_REQUEST: {
      const index = findIndex(state.members, ['_id', action.payload._id]);
      if (index > -1) {
        const member = state.members[index];
        return {
          ...state,
          members: [
            ...state.members.slice(0, index),
            { ...member, followerCount: member.followerCount - 1, isSubscribed: false },
            ...state.members.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case SUBSCRIBE_FAILURE: {
      const index = findIndex(state.members, ['_id', action.payload._id]);
      if (index > -1) {
        const member = state.members[index];
        return {
          ...state,
          members: [
            ...state.members.slice(0, index),
            { ...member, followerCount: member.followerCount - 1, isSubscribed: false },
            ...state.members.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case UNSUBSCRIBE_FAILURE: {
      const index = findIndex(state.members, ['_id', action.payload._id]);
      if (index > -1) {
        const member = state.members[index];
        return {
          ...state,
          members: [
            ...state.members.slice(0, index),
            { ...member, followerCount: member.followerCount + 1, isSubscribed: true },
            ...state.members.slice(index + 1),
          ],
        };
      }
      return state;
    }
    case DELETE_POST_SUCCESS:
    case DELETE_COMMENT_SUCCESS:
      return { ...state, posts: state.posts.filter(post => post._id !== action.payload) };
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS: {
      let newState = { ...state };
      newState.posts.forEach((item, index) => {
        if (item._id === action.payload._id) {
          const currentPost = post(item, action);
          newState = {
            ...newState,
            posts: [...newState.posts.slice(0, index), { ...currentPost }, ...newState.posts.slice(index + 1)],
          };
        }
        if (item.postId === action.payload._id) {
          const currentPost = post(item, action);
          newState = {
            ...newState,
            posts: [...newState.posts.slice(0, index), { ...currentPost }, ...newState.posts.slice(index + 1)],
          };
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

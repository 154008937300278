import { get, nthArg } from 'lodash';
import uri from 'urijs';

import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { getCategoryIds } from '../../common/selectors/categories-selectors';
import { normalizePosts } from '../../common/services/post-utils';

export const FETCH_RECENT_POSTS_REQUEST = 'recentPosts/FETCH_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'recentPosts/FETCH_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'recentPosts/FETCH_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(FETCH_RECENT_POSTS_SUCCESS, nthArg(0), nthArg(1));
export const fetchRecentPostsFailure = createAction(FETCH_RECENT_POSTS_FAILURE, nthArg(0), nthArg(1));

export const POST_COUNT = 3;

export const getQuery = post =>
  uri('/_api/posts').query({
    categoryIds: get(post, 'categoryIds', []).join(','),
    excludeIds: post._id,
    size: POST_COUNT,
    offset: 0,
  });

export default function fetchRecentPosts(post) {
  return (dispatch, getState, { request }) => {
    const params = { postId: post._id };
    dispatch(fetchRecentPostsRequest(params));

    const promise = request(getQuery(post));

    // prettier-ignore
    return promise
      .then(posts =>
        dispatch(fetchRecentPostsSuccess(
          normalizePosts({ posts, blogCategoryIds: getCategoryIds(getState()) }),
          params
        )))
      .catch(() => dispatch(fetchRecentPostsFailure(undefined, params)))
      .then(() => promise);
  };
}

import { get } from 'lodash';
import {
  DEFAULT_CATEGORY_CHAR_COUNT,
  DEFAULT_SHOW_ALL_POSTS_CATEGORY,
  DEFAULT_SHOW_POSTS_NUMBER,
  propertyKeys,
} from '../constants/category-list-widget';
import { DESKTOP } from '../../common/constants/widget-device-types';
import { getMainPanelProperties } from '../../common/selectors/config-selectors';

export const getShowAllPostsCategory = (config, deviceType = DESKTOP) =>
  get(
    getMainPanelProperties(config, deviceType),
    [propertyKeys.SHOW_ALL_POSTS_CATEGORY],
    DEFAULT_SHOW_ALL_POSTS_CATEGORY,
  );

export const getShowPostsNumber = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.SHOW_POSTS_NUMBER], DEFAULT_SHOW_POSTS_NUMBER);

export const getCategoryCharCount = (config, deviceType = DESKTOP) =>
  get(getMainPanelProperties(config, deviceType), [propertyKeys.CATEGORY_CHAR_COUNT], DEFAULT_CATEGORY_CHAR_COUNT);

import { POST_PAGE_RESIZE } from '../actions/post-page-resize';

const handler = action => ({
  evid: 309,
  origin: action.payload.viewMode,
  buildertype: action.payload.buildertype,
  width: action.payload.width,
  eventMeta: {
    description: 'Resize post page event',
  },
});

export const usersPostPageResizeEvent = { [POST_PAGE_RESIZE]: handler };

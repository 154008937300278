import { SET_VIEW_MODE } from './view-mode-actions';

export default function viewMode(state = 'site', action) {
  switch (action.type) {
    case SET_VIEW_MODE:
      return action.payload;
    default:
      return state;
  }
}

import { FETCH_WRITERS_SUCCESS, SET_WRITERS } from './writers-actions';

const initialState = [];

const writers = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case FETCH_WRITERS_SUCCESS:
    case SET_WRITERS:
      return payload;

    default:
      return state;
  }
};

export default writers;

import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';
import { setIsSaving } from '../store/is-saving/is-saving-actions';
import { savePostDraft } from './save-post-draft';
import { publishPostAndNavigate } from './publish-post';

export const saveAndPublishPost = values => dispatch => {
  dispatch(setIsSaving(true));
  return dispatch(savePostDraft(values)).then(({ _id }) => dispatch(publishPostAndNavigate(_id)));
};

export const saveAndPublishPostPromisified = createPromisifiedAction(
  saveAndPublishPost,
  data => data,
  response => response.status,
);

import { addCategoryFormFields } from '../../services/category-form';

export const UPDATE_CATEGORY_REQUEST = 'category/UPDATE_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'category/UPDATE_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'category/UPDATE_FAILURE';

export const updateCategoryRequest = payload => ({ type: UPDATE_CATEGORY_REQUEST, payload });
export const updateCategorySuccess = (payload, meta = {}) => ({ type: UPDATE_CATEGORY_SUCCESS, payload, meta });
export const updateCategoryFailure = payload => ({ type: UPDATE_CATEGORY_FAILURE, payload });

export function updateCategory({ categoryId, category, meta }) {
  return (dispatch, getState, { request }) => {
    dispatch(updateCategoryRequest(categoryId));

    const promise = request.patch(
      `/_api/categories/${categoryId}`,
      addCategoryFormFields({ categoryId, values: category, state: getState() }),
    );
    return promise
      .then(payload => dispatch(updateCategorySuccess(payload, meta)))
      .catch(() => dispatch(updateCategoryFailure(categoryId)))
      .then(() => promise);
  };
}

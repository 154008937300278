import { SET_USER_AGENT } from './user-agent-actions';

const userAgent = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER_AGENT:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default userAgent;

import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePost } from '../services/post-utils';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const PIN_POST_REQUEST = 'post/PIN_REQUEST';
export const PIN_POST_SUCCESS = 'post/PIN_SUCCESS';
export const PIN_POST_FAILURE = 'post/PIN_FAILURE';

export function pinPost(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: PIN_POST_REQUEST,
    });

    const promise = request.post(`/_api/posts/${id}/pin`);

    return promise
      .then(post =>
        dispatch({
          type: PIN_POST_SUCCESS,
          payload: normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) }),
        }),
      )
      .catch(() =>
        dispatch({
          type: PIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}

export const pinPostPromisified = createPromisifiedAction(
  pinPost,
  data => data,
  response => response.status,
);

import { get, isObject } from 'lodash';

export const DEFAULT_LANGUAGE = 'en';
const placeholderRegex = new RegExp('{{([^{{]+)}}', 'gm');
let translations;
let language;

const decorate = (param, decorator) => {
  switch (decorator) {
    case 'number':
      return Number(param);
    default:
      return param;
  }
};

const flatten = target => {
  const output = {};

  const step = (object, prev) =>
    Object.keys(object).forEach(key => {
      const value = object[key];
      const newKey = prev ? `${prev}.${key}` : key;

      if (isObject(value) && Object.keys(value).length) {
        return step(value, newKey);
      }

      output[newKey] = value;
    });

  step(target);

  return output;
};

export const initI18N = (_translations, _language = DEFAULT_LANGUAGE) => {
  translations = flatten(_translations);
  language = _language;
};

export const translate = (key, params, lang = language) => {
  if (!translations) {
    return console.error('Translations not initialized.');
  }
  const path = `${key}.${lang}`;
  const translation = get(
    translations,
    path,
    lang !== DEFAULT_LANGUAGE ? translate(key, params, DEFAULT_LANGUAGE) : key,
  );

  if (!params) {
    return translation;
  }

  const matches = translation.match(placeholderRegex);
  if (!matches) {
    return translation;
  }

  return matches.reduce((acc, match) => {
    const [paramKey, decorator] = match.replace(/[{}\s]/g, '').split(',');
    return acc.replace(match, decorate(params[paramKey], decorator));
  }, translation);
};

export {
  initializeStoreBaseData,
  initUserDependentStoreBaseData,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

import {
  initializeActions as initializeCommonActions,
  initializePromisifiedActions as initializeCommonPromisifiedActions,
} from '../../common/controller/init-actions';
import { setIsLoaded } from '../../common/store/is-loaded/is-loaded-actions';
import { addCategory } from '../../common/store/categories/add-category';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import { cleanPostEditorState, updatePostEditorPost } from '../../common/store/post-editor/post-editor-actions';
import { fetchOembedPromisified } from '../../common/actions/fetch-oembed';
import { addTextColor } from '../actions/rce-settings';
import { postPageResized } from '../actions/post-page-resize';
import { createCategoryFromLabelPromisified } from '../../common/store/categories/create-category-from-label';
import { bindActionCreators } from 'redux';
import { saveAndPublishPostPromisified } from '../../common/actions/save-and-publish-post';

export function initializeActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }) {
  return {
    ...initializeCommonActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }),
    ...bindActionCreators(
      {
        fetchRecentPosts,
        setIsLoaded,
        cleanPostEditorState,
        updatePostEditorPost,
        addCategory,
        addTextColor,
        postPageResized,
      },
      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions({ store }) {
  return {
    ...initializeCommonPromisifiedActions({ store }),
    ...bindActionCreators(
      {
        saveAndPublishPostPromisified,
        createCategoryFromLabelPromisified,
        fetchOembedPromisified,
      },
      store.dispatch,
    ),
  };
}

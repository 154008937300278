import { CREATE_USER_SUCCESS } from '../../../actions/create-user';
import { SET_USER } from '../../../actions/set-user';
import { LOGOUT_MEMBER_SUCCESS } from '../../../actions/logout-member';
import { SET_AUTH } from '../../../actions/set-auth';

export default (state = false, { type, payload }) => {
  switch (type) {
    case SET_USER:
    case CREATE_USER_SUCCESS:
      return true;
    case LOGOUT_MEMBER_SUCCESS:
      return false;
    case SET_AUTH:
      return Boolean(payload.isAuthenticated);
    default:
      return state;
  }
};

export const INCREMENT_POST_VIEW_COUNT_REQUEST = 'post/INCREMENT_VIEW_COUNT_REQUEST';

export function incrementPostViewCount(postId) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: INCREMENT_POST_VIEW_COUNT_REQUEST,
    });

    return request.post(`/_api/posts/${postId}/views`);
  };
}

import { values, includes } from 'lodash';
import { getTopology } from '../store/topology/topology-selectors';
import { INSTANCE_ID_WIX } from '@wix/communities-universal/dist/src/constants/wix-blogs';

export const navigateWithinBlog = path => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateWithinPostPage = path => (dispatch, getState, { wixCodeApi }) => {
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(getState());
  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

export const navigateProGalleryWithinPostPage = path => (dispatch, getState, { wixCodeApi, appParams }) => {
  // Temporary hack for marketing sites, because wix code navigation doesn't work properly
  const { baseUrl, postPageSectionUrl: sectionUrl } = getTopology(getState());

  if (includes(values(INSTANCE_ID_WIX), appParams.instanceId)) {
    return wixCodeApi.location.to(`${baseUrl}${path}`);
  }

  const sectionPath = sectionUrl.replace(baseUrl, '');
  return wixCodeApi.location.to(`${sectionPath}${path}`);
};

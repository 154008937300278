import _ from 'lodash';
import { CLEAR_MEMBERS } from '../clear-members';
import { FETCH_MEMBERS_SUCCESS } from '../fetch-members';

export default (state = [], action = {}) => {
  switch (action.type) {
    case CLEAR_MEMBERS:
      return [];
    case FETCH_MEMBERS_SUCCESS: {
      const idList = action.payload.map(member => member._id);
      return action.meta.page > 1 ? _.union(state, idList) : idList;
    }
    default:
      return state;
  }
};

import { SET_ROUTER_MATCH } from '../../common/router';
import { isInPostPage } from '../../common/services/detect-route';
import { resolvePostSlug } from '../../common/services/slug';
import { getPostBySlug } from '../../common/selectors/post-selectors';
import { emitOpenPost, emitClosePost } from '../actions/post-socket';

export const createPostChannelMiddleware = () => {
  let previousPost;

  return store => next => action => {
    if (action.type === SET_ROUTER_MATCH) {
      if (previousPost) {
        store.dispatch(emitClosePost(previousPost));
        previousPost = undefined;
      }

      const { route, params } = action.payload;
      if (isInPostPage(route)) {
        const postSlug = resolvePostSlug(params);
        const post = getPostBySlug(store.getState(), postSlug);
        previousPost = post;
        store.dispatch(emitOpenPost(post));
      }
    }

    return next(action);
  };
};

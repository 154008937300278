import { FETCH_EXPERIMENTS_SUCCESS } from './experiments-actions';

export default function experiments(state = {}, action) {
  switch (action.type) {
    case FETCH_EXPERIMENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

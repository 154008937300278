import { FETCH_SITE_PROPERTIES_SUCCESS } from './site-properties-actions';

export default function siteProperties(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_SITE_PROPERTIES_SUCCESS:
      return payload;
    default:
      return state;
  }
}

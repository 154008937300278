import { omit, pick } from 'lodash';
import { FETCH_COMMENT_SUCCESS } from '../comments/fetch-comment';
import { FETCH_COMMENTS_SUCCESS } from '../comments/fetch-comments';
import {
  INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
  INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
} from './comment-likes-actions';

export default function commentLikes(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_COMMENT_SUCCESS:
      return comment(state, payload.comment);
    case FETCH_COMMENTS_SUCCESS:
      return comments(state, payload.comments);
    case INCREMENT_COMMENT_LIKE_COUNT_REQUEST:
      return likeRequest(state, payload._id);
    case INCREMENT_COMMENT_LIKE_COUNT_SUCCESS:
      return likeSuccess(state, payload._id);
    case INCREMENT_COMMENT_LIKE_COUNT_FAILURE:
      return likeFailure(state, payload._id);
    default:
      return state;
  }
}

function comment(state, comment) {
  return { ...state, [comment._id]: getLikes(comment) };
}

function comments(state, comments) {
  return {
    ...state,
    ...comments.reduce((result, comment) => {
      result[comment._id] = getLikes(comment);
      return result;
    }, {}),
  };
}

function likeRequest(state, _id) {
  const comment = state[_id];
  if (!comment) {
    return state;
  }

  return {
    ...state,
    [_id]: {
      isLiked: !comment.isLiked,
      likeCount: comment.likeCount + (comment.isLiked ? -1 : 1),
      isLikeInProgress: true,
    },
  };
}

function likeSuccess(state, _id) {
  const comment = state[_id];
  if (!comment) {
    return state;
  }

  return {
    ...state,
    [_id]: omit(comment, 'isLikeInProgress'),
  };
}

function likeFailure(state, _id) {
  const comment = state[_id];
  if (!comment) {
    return state;
  }

  return {
    ...state,
    [_id]: {
      isLiked: !comment.isLiked,
      likeCount: comment.likeCount + (comment.isLiked ? -1 : 1),
    },
  };
}

function getLikes(comment) {
  return pick(comment, ['likeCount', 'isLiked']);
}

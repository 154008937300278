import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { getMetaSiteId, getIsDemoMode, getSiteIsTemplate } from '../instance-values/instance-values-selectors';

export const FETCH_SITE_PROPERTIES_REQUEST = 'siteProperties/FETCH_REQUEST';
export const FETCH_SITE_PROPERTIES_SUCCESS = 'siteProperties/FETCH_SUCCESS';
export const FETCH_SITE_PROPERTIES_FAILURE = 'siteProperties/FETCH_FAILURE';

export const fetchSitePropertiesRequest = createAction(FETCH_SITE_PROPERTIES_REQUEST);
export const fetchSitePropertiesSuccess = createAction(FETCH_SITE_PROPERTIES_SUCCESS);
export const fetchSitePropertiesFailure = createAction(FETCH_SITE_PROPERTIES_FAILURE);

export const fetchSiteProperties = metaSiteId => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchSitePropertiesRequest());

    const state = getState();
    const shouldSkipFetch = !getMetaSiteId(state) || getIsDemoMode(state) || getSiteIsTemplate(state);
    if (shouldSkipFetch) {
      dispatch(fetchSitePropertiesSuccess({}));
      return;
    }

    const promise = request(`/_api/site-properties?cacheId=${metaSiteId}`);
    return promise
      .then(
        siteProperties => dispatch(fetchSitePropertiesSuccess(siteProperties)),
        () => dispatch(fetchSitePropertiesFailure()),
      )
      .then(() => Promise.resolve());
  };
};

import { Duplexer } from '@wix/wix-duplexer-js';

export default function createDuplexerConnection({ duplexerUrl, blogApiBaseUrl, instance }) {
  const duplexerClient = new Duplexer(duplexerUrl);
  return duplexerClient.connect({
    apiKey: '8dd942aa-6255-4bb0-b004-8bd6cf2f6b35', // HACK
    auth: {
      url: `${blogApiBaseUrl}/_api/duplexer/auth`,
      onBeforeRequest: () => ({
        headers: {
          instance,
        },
      }),
    },
  });
}

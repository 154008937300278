import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const FETCH_CATEGORIES_REQUEST = 'categories/FETCH_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'categories/FETCH_FAILURE';

export const fetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST);
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

const fetchCategories = metaSiteId => (dispatch, _getState, { request }) => {
  dispatch(fetchCategoriesRequest());

  // const promise = request.metered('/_api/categories')('/_api/categories'); // TODO: fix metered request
  const promise = request(`/_api/categories?cacheId=${metaSiteId}`);
  return promise
    .then(categories => dispatch(fetchCategoriesSuccess(categories)))
    .catch(() => dispatch(fetchCategoriesFailure()));
};

export default fetchCategories;

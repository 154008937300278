import { isEmpty, noop, isArray } from 'lodash';
import postService from '../../common/services/post';
import helpers from '../../common/services/helpers';

import { getLastPublishedDate } from '../../common/selectors/post-selectors';
import {
  itemSelectors,
  arrowDirections,
  POST_TICKER_TITLE_FONT_SIZE_MAX,
  POST_TICKER_DATE_FONT_SIZE_MAX,
  POST_TICKER_TITLE_MAX_HEIGHT,
} from '../constants/post-ticker-widget';
import { EMPTY_STATE, FULL_STATE } from '../../common/constants/state-box-states';
import {
  getAnimationAutoplay,
  getCategory,
  getFeaturedPostsEnabled,
  getPostListSize,
  getPostOrderType,
  getTimeBetweenImages,
  getTitleCharCount,
} from '../selectors/post-ticker-widget-selectors';
import { getIsNotEditor } from '../../common/selectors/wix-code-api';

export const createPostTickerController = ({ $w, appParams, wixCodeApi, config }) => ({
  pageReady: () => {
    let tickerPosts = [];
    let currentPostIndex = 0;

    const stateBox = $w(itemSelectors.STATE_BOX);
    const next = $w(itemSelectors.RIGHT_ARROW);
    const prev = $w(itemSelectors.LEFT_ARROW);
    const emptyStateText = $w(itemSelectors.EMPTY_STATE_TEXT);
    const title = $w(itemSelectors.POST_TITLE);
    const date = $w(itemSelectors.POST_DATE);

    const setContent = ({ post }) => {
      // font size limit needed because app builder does not provide it (yet)
      const applyFontSizeLimit = (html, limit) => {
        const rx = /(font-size:)(.*?)(;)/g;
        const stylesToChange = html.match(rx);

        const fontSizeLimit = `font-size: ${limit}px;`;

        isArray(stylesToChange) &&
          stylesToChange.forEach(oldFontSize => {
            html = html.replace(oldFontSize, fontSizeLimit);
          });

        return html;
      };

      // max height needed because app builder does not provide it (yet)
      const applyMaxHeight = (html, maxHeight) => {
        const i = html.indexOf('style="');

        if (i === -1) {
          return html;
        }

        const position = i + 7;

        return [html.slice(0, position), `max-height:${maxHeight}px;overflow:hidden;`, html.slice(position)].join('');
      };

      $w(itemSelectors.POST_IMAGE).src = post.coverImage;

      date.text = helpers.getDateString(getLastPublishedDate(post));
      date.html = applyFontSizeLimit(date.html, POST_TICKER_DATE_FONT_SIZE_MAX);

      title.text = helpers.applyCharLimitEllipsis(post.title, getTitleCharCount(config));
      title.onClick(() => wixCodeApi.location.to(post.postPageUrl));
      title.html = applyMaxHeight(
        applyFontSizeLimit(title.html, POST_TICKER_TITLE_FONT_SIZE_MAX),
        POST_TICKER_TITLE_MAX_HEIGHT,
      );
    };

    const changeSlide = direction => () => {
      const updateCurrentPostIndex = () => {
        if (direction === arrowDirections.NEXT) {
          currentPostIndex = currentPostIndex + 1 === tickerPosts.length ? 0 : currentPostIndex + 1;
        } else {
          currentPostIndex = currentPostIndex === 0 ? tickerPosts.length - 1 : currentPostIndex - 1;
        }
      };

      const moveToPost = async () => {
        const hideForChange = () => {
          emptyStateText.hide();
          prev.hide();
          next.hide();
        };

        const showAfterChange = () => {
          emptyStateText.show();
          next.show();
          prev.show();
        };

        hideForChange();

        await stateBox.changeState(EMPTY_STATE);
        setContent({ post: tickerPosts[currentPostIndex] });
        await stateBox.changeState(FULL_STATE);

        showAfterChange();
      };

      updateCurrentPostIndex();
      moveToPost();
    };

    const showNextPost = changeSlide(arrowDirections.NEXT);
    const showPrevPost = changeSlide(arrowDirections.PREV);

    let clearAnimationInterval = noop;
    const maybeRunAnimationAutoplay = () => {
      if (getIsNotEditor(wixCodeApi) && tickerPosts.length > 1 && getAnimationAutoplay(config)) {
        const sliderIntervalId = setInterval(() => {
          showNextPost();
        }, helpers.secondsToMiliseconds(getTimeBetweenImages(config)));

        clearAnimationInterval = () => {
          clearInterval(sliderIntervalId);
          clearAnimationInterval = noop;
        };
      }
    };

    next.onClick(() => {
      clearAnimationInterval();
      showNextPost();
    });
    prev.onClick(() => {
      clearAnimationInterval();
      showPrevPost();
    });

    const category = getCategory(config);
    const order = helpers.getPostOrderTypeQuery(getPostOrderType(config));
    const postListSize = getPostListSize(config);

    const setArrows = () => {
      if (tickerPosts.length === 1) {
        next.hide();
        prev.hide();
      }
    };

    const params = {
      limit: postListSize,
      featured: getFeaturedPostsEnabled(config),
      ...(category && { category }),
      ...(order && { order }),
    };

    return postService
      .getPosts({ instance: appParams.instance, params, wixCodeApi })
      .then(posts => {
        if (isEmpty(posts)) {
          stateBox.changeState(EMPTY_STATE);
        } else {
          tickerPosts = posts;
          setArrows();
          setContent({ post: posts[currentPostIndex] });
          maybeRunAnimationAutoplay();
        }
      })
      .then(() => {
        $w(itemSelectors.MEDIA_BOX).show();
      })
      .catch(console.error);
  },
});

import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { sanitizeContent } from '@wix/communities-blog-universal/dist/src/utils/sanitize-content';

import { extractHashtagsFromContent } from '../../services/hashtag-utils';
import { createPromisifiedAction } from '../../actions-promisifier/create-promisified-action';

export const CREATE_COMMENT_REQUEST = 'comment/CREATE_REQUEST';
export const CREATE_COMMENT_SUCCESS = 'comment/CREATE_SUCCESS';
export const CREATE_COMMENT_FAILURE = 'comment/CREATE_FAILURE';

export const createCommentRequest = createAction(CREATE_COMMENT_REQUEST);
export const createCommentSuccess = createAction(CREATE_COMMENT_SUCCESS);
export const createCommentFailure = createAction(CREATE_COMMENT_FAILURE);

export function createComment(postId, { content } = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createCommentRequest());
    const comment = sanitizeContent({ content });
    const promise = request.post(`/_api/posts/${postId}/comments`, {
      ...comment,
      hashtags: extractHashtagsFromContent(comment.content),
    });

    return promise
      .then(data => dispatch(createCommentSuccess(data)))
      .catch(() => dispatch(createCommentFailure()))
      .then(() => promise);
  };
}

export const createCommentPromisified = createPromisifiedAction(
  createComment,
  () => null,
  response => response.status,
);

import { filter } from 'lodash';

import { FETCH_ARCHIVE_SUCCESS, FETCH_ARCHIVE_FAILURE } from '../../actions/fetch-archive';
import { UPDATE_POST_SUCCESS } from '../../actions/update-post';
import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import { ENABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/enable-post-comments';
import { DISABLE_POST_COMMENTS_SUCCESS } from '../../../comments/actions/disable-post-comments';
import { PIN_POST_SUCCESS } from '../../actions/pin-post';
import { UNPIN_POST_SUCCESS } from '../../actions/unpin-post';
import { UPDATE_POST } from '../../../post-page/actions/post-socket';

// eslint-disable-next-line complexity
const post = (state, action) => {
  switch (action.type) {
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
    case UPDATE_POST:
    case UPDATE_POST_SUCCESS:
      return { ...action.payload };
    default:
      return state;
  }
};

// eslint-disable-next-line complexity
const posts = (state = { posts: [], entityCount: 0 }, action = {}) => {
  const { type, payload, meta } = action;
  switch (type) {
    case FETCH_ARCHIVE_FAILURE:
      return { ...state };
    case FETCH_ARCHIVE_SUCCESS:
      return { ...state, posts: payload, entityCount: meta.entityCount };
    case DELETE_POST_SUCCESS: {
      const newState = { ...state };
      let { entityCount } = newState;
      newState.posts = filter(newState.posts, post => {
        if (post._id === payload) {
          entityCount--;
        }
        return post._id !== payload;
      });

      newState.entityCount = entityCount;

      return newState;
    }
    case PIN_POST_SUCCESS:
    case UNPIN_POST_SUCCESS:
    case UPDATE_POST:
    case UPDATE_POST_SUCCESS: {
      let newState = { ...state };
      newState.posts.forEach((item, index) => {
        if (item._id === payload._id) {
          const currentPost = post(item, { type, payload });
          newState = {
            ...newState,
            posts: [...newState.posts.slice(0, index), { ...currentPost }, ...newState.posts.slice(index + 1)],
          };
        }
      });
      return newState;
    }
    case ENABLE_POST_COMMENTS_SUCCESS:
    case DISABLE_POST_COMMENTS_SUCCESS:
      return {
        ...state,
        posts: state.posts.map(entity => post(entity, { type, payload })),
      };
    default:
      return state;
  }
};

export default posts;

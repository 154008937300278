import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePost } from '../services/post-utils';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const UNPIN_POST_REQUEST = 'post/UNPIN_REQUEST';
export const UNPIN_POST_SUCCESS = 'post/UNPIN_SUCCESS';
export const UNPIN_POST_FAILURE = 'post/UNPIN_FAILURE';

export function unpinPost(id) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: UNPIN_POST_REQUEST,
    });

    const promise = request.post(`/_api/posts/${id}/unpin`);

    return promise
      .then(post =>
        dispatch({
          type: UNPIN_POST_SUCCESS,
          payload: normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) }),
        }),
      )
      .catch(() =>
        dispatch({
          type: UNPIN_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}

export const unpinPostPromisified = createPromisifiedAction(
  unpinPost,
  data => data,
  response => response.status,
);

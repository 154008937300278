import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { sanitizeContent } from '@wix/communities-blog-universal/dist/src/utils/sanitize-content';
import { getCategoryIds } from '../selectors/categories-selectors';
import { normalizePost } from '../services/post-utils';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const CREATE_POST_DRAFT_REQUEST = 'post/DRAFT_CREATE_REQUEST';
export const CREATE_POST_DRAFT_SUCCESS = 'post/DRAFT_CREATE_SUCCESS';
export const CREATE_POST_DRAFT_FAILURE = 'post/DRAFT_CREATE_FAILURE';

export const createPostDraftRequest = createAction(CREATE_POST_DRAFT_REQUEST);
export const createPostDraftSuccess = createAction(CREATE_POST_DRAFT_SUCCESS);
export const createPostDraftFailure = createAction(CREATE_POST_DRAFT_FAILURE);

export function createPostDraft({ title, content, coverImage = null } = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createPostDraftRequest());
    const promise = request.post(`/_api/posts/`, {
      draft: sanitizeContent({
        title,
        content,
        ...(coverImage && { coverImage }),
      }),
    });

    return promise
      .then(post => {
        dispatch(createPostDraftSuccess(normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) })));
        return post;
      })
      .catch(() => dispatch(createPostDraftFailure()))
      .then(() => promise);
  };
}

export const createPostDraftPromisified = createPromisifiedAction(
  createPostDraft,
  data => data,
  response => response.status,
);

import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const FETCH_POST_METADATA_REQUEST = 'postMetadata/FETCH_REQUEST';
export const FETCH_POST_METADATA_SUCCESS = 'postMetadata/FETCH_SUCCESS';
export const FETCH_POST_METADATA_FAILURE = 'postMetadata/FETCH_FAILURE';

export const fetchPostMetadataRequest = createAction(FETCH_POST_METADATA_REQUEST);
export const fetchPostMetadataSuccess = createAction(FETCH_POST_METADATA_SUCCESS);
export const fetchPostMetadataFailure = createAction(FETCH_POST_METADATA_FAILURE);

export const fetchPostMetadata = postId => {
  return (dispatch, _, { request }) => {
    dispatch(fetchPostMetadataRequest());

    const promise = request(`/_api/posts/metadata/${postId}`);
    return promise
      .then(
        postMetadata => dispatch(fetchPostMetadataSuccess(postMetadata)),
        () => dispatch(fetchPostMetadataFailure()),
      )
      .then(() => promise);
  };
};

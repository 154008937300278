const STATE_BOX = '@stateBox';
const MEDIA_BOX = '@mediaBox';
const CATEGORY_LIST = '@categoryList';
const LIST_ITEM = '@listItem';

export const itemSelectors = {
  STATE_BOX,
  MEDIA_BOX,
  CATEGORY_LIST,
  LIST_ITEM,
};

const SHOW_ALL_POSTS_CATEGORY = 'showAllPostsCategory';
const SHOW_POSTS_NUMBER = 'showPostsNumber';
const CATEGORY_CHAR_COUNT = 'categoryCharCount';

export const propertyKeys = {
  SHOW_ALL_POSTS_CATEGORY,
  SHOW_POSTS_NUMBER,
  CATEGORY_CHAR_COUNT,
};

export const DEFAULT_SHOW_ALL_POSTS_CATEGORY = false;
export const DEFAULT_SHOW_POSTS_NUMBER = false;
export const DEFAULT_CATEGORY_CHAR_COUNT = 35;

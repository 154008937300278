import { addCategoryFormFields } from '../../services/category-form';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const CREATE_CATEGORY_REQUEST = 'category/CREATE_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'category/CREATE_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'category/CREATE_FAILURE';

export const createCategoryRequest = createAction(CREATE_CATEGORY_REQUEST);
export const createCategorySuccess = createAction(CREATE_CATEGORY_SUCCESS);
export const createCategoryFailure = createAction(CREATE_CATEGORY_FAILURE);

export function createCategory(values = {}) {
  return (dispatch, getState, { request }) => {
    dispatch(createCategoryRequest());

    const promise = request.post(`/_api/categories/`, addCategoryFormFields({ values, state: getState() }));

    return promise
      .then(category => dispatch(createCategorySuccess(category)))
      .catch(() => dispatch(createCategoryFailure()))
      .then(() => promise);
  };
}

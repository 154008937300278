import { get, chain, filter, some } from 'lodash';

export const getImageCount = content =>
  filter(content.entityMap, entity => entity.type === 'wix-draft-plugin-image').length;

export const getVideoCount = content =>
  filter(content.entityMap, entity => entity.type === 'wix-draft-plugin-video').length;

export const getQuoteCount = content => {
  return filter(content.blocks, block => block.type === 'blockquote').length;
};

export const getCharacterCount = content => {
  return filter(content.blocks, block => block.type !== 'atomic').reduce(
    (count, block) => get(block, 'text', '').length + count,
    0,
  );
};

export const hasHtml = content => some(content.entityMap, entity => entity.type === 'wix-draft-plugin-html');

export const getGalleries = content => {
  const galleries = chain(content.entityMap)
    .filter(entity => entity.type === 'wix-draft-plugin-gallery')
    .map(entity => ({
      layout: entity.data.config.layout,
      length: entity.data.items.length,
    }))
    .value();

  return galleries.length ? JSON.stringify(galleries) : null;
};

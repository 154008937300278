export const itemSelectors = {
  STATE_BOX: '@stateBox',
  MEDIA_BOX: '@mediaBox',
  POST_TICKER: '@postTicker',
  POST_IMAGE: '@postImage',
  POST_DATE: '@postDate',
  LEFT_ARROW: '@prev',
  RIGHT_ARROW: '@next',
  POST_TITLE: '@postTitle',
  EMPTY_STATE_TEXT: '@emptyStateText',
};

export const stateboxStates = {
  EMPTY_STATE: 'emptyState',
  FULL_STATE: 'fullState',
};

export const arrowDirections = {
  NEXT: 'directionNext',
  PREV: 'directionPrev',
};

const FEATURED_POSTS = 'featuredPosts';
const POST_LIST_SIZE = 'postListSize';
const CATEGORY = 'category';
const TITLE_CHAR_COUNT = 'titleCharCount';
const ANIMATION_AUTOPLAY = 'animationAutoplay';
const TIME_BETWEEN_IMAGES = 'timeBetweenImages';
const POST_ORDER_TYPE = 'postOrderType';

export const propertyKeys = {
  FEATURED_POSTS,
  POST_LIST_SIZE,
  CATEGORY,
  TITLE_CHAR_COUNT,
  ANIMATION_AUTOPLAY,
  TIME_BETWEEN_IMAGES,
  POST_ORDER_TYPE,
};

// todo char count max and default
export const POST_TICKER_TITLE_CHAR_COUNT_MAX = 100;
export const CATEGORY_SHOW_ALL = null;
export const ANIMATION_AUTOPLAY_ENABLED = true;

export const DEFAULT_CATEGORY = null;
export const DEFAULT_FEATURED_POSTS = false;
export const DEFAULT_POST_LIST_SIZE = 3;
export const DEFAULT_TITLE_CHAR_COUNT = 48;
export const DEFAULT_ANIMATION_AUTOPLAY = ANIMATION_AUTOPLAY_ENABLED;
export const DEFAULT_TIME_BETWEEN_IMAGES = 7;

// todo - remove max font size?
export const POST_TICKER_TITLE_FONT_SIZE_MAX = 22;
export const POST_TICKER_DATE_FONT_SIZE_MAX = 14;
export const POST_TICKER_TITLE_MAX_HEIGHT = 46;

import { head } from 'lodash';
import fetchFeedPosts from '../../common/actions/fetch-feed-posts';
import { ENTITY_TYPE_POSTS } from '@wix/communities-blog-client-common/dist/src/constants/entity-types';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';

export const FETCH_LAST_POST_REQUEST = 'lastPost/FETCH_REQUEST';
export const FETCH_LAST_POST_SUCCESS = 'lastPost/FETCH_SUCCESS';
export const FETCH_LAST_POST_FAILURE = 'lastPost/FETCH_FAILURE';

export const fetchLastPostRequest = createAction(FETCH_LAST_POST_REQUEST);
export const fetchLastPostSuccess = createAction(FETCH_LAST_POST_SUCCESS);
export const fetchLastPostFailure = createAction(FETCH_LAST_POST_FAILURE);

export const fetchLastPost = () => dispatch => {
  dispatch(fetchLastPostRequest());

  const promise = dispatch(
    fetchFeedPosts({
      page: 1,
      pageSize: 1,
      entityType: ENTITY_TYPE_POSTS,
      featuredOnly: false,
    }),
  );

  let lastPost;
  return promise
    .then(({ body: posts }) => {
      lastPost = head(posts);
      dispatch(fetchLastPostSuccess(lastPost));
    })
    .catch(() => dispatch(fetchLastPostFailure()))
    .then(() => lastPost);
};

import { SET_ACTIVE_TAB } from './set-active-tab';

export const INITIAL_STATE = {};

export default function activeTab(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

import { SHOW_HEADER_LOGIN } from './show-header-login';
import { SET_VIEW_MODE } from '../view-mode/view-mode-actions';

const initialState = {
  showLogin: true,
};

export default function headerWidget(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_HEADER_LOGIN:
      return {
        showLogin: action.payload,
      };
    case SET_VIEW_MODE:
      return initialState;
    default:
      return state;
  }
}

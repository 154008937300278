import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
import { deletePost } from '../actions/delete-post';
import { deletePostPermanently } from '../actions/delete-post-permanently';
import * as messageTypes from '../components/message/messages';
import { STATUS_CONFLICT } from '@wix/communities-blog-client-common/dist/src/constants/errors';
import { getRoute } from '../router/router-selectors';
import { navigateWithinBlog } from '../actions/navigate-within-blog';
import { isInPostPage } from './detect-route';

const getPostDeleteSuccessMessage = (postStatus, permanently) =>
  !permanently
    ? postStatus === POST_STATUS.unpublished
      ? messageTypes.DRAFT_MOVE_TO_TRASH
      : messageTypes.POST_MOVE_TO_TRASH
    : postStatus === POST_STATUS.unpublished
    ? messageTypes.DRAFT_DELETE
    : messageTypes.POST_DELETE;

const handlePostDeleteError = ({ response, showMessage }) => {
  if (response.status === STATUS_CONFLICT) {
    showMessage(messageTypes.POST_DELETE_ANOTHER_WRITER_EDITING_ERROR);
  }
};

const getNavigatePathname = state => (isInPostPage(getRoute(state)) ? '/' : undefined);

export const handlePostDelete = ({ permanently }) => ({ postId, postStatus }, { showMessage }) => (
  dispatch,
  getState,
) => {
  const message = getPostDeleteSuccessMessage(postStatus, permanently);
  const navigateTo = getNavigatePathname(getState());
  const action = permanently ? deletePostPermanently(postId, navigateTo) : deletePost(postId, navigateTo);

  return dispatch(action)
    .then(() => {
      if (navigateTo) {
        dispatch(navigateWithinBlog(navigateTo));
      }
      showMessage(message);
    })
    .catch(response => handlePostDeleteError({ response, showMessage }));
};

import { get } from 'lodash';
import createAction from '@wix/communities-blog-client-common/dist/src/services/create-action';
import { AMP_BASE_URLS, WP_BLOGS } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import {
  BLOG_APP_DEBUG,
  LOG_BI_TO_CONSOLE,
  LOG_REDUX_ACTIONS,
  LOG_BLOG_POST_EDITOR_ACTIONS,
} from '@wix/communities-universal/dist/src/constants/appsConfig';

import getJsonSchemaPublisher from '../../services/get-json-schema-publisher';
import { getEnvironment } from '../../controller/helpers';
import { getAppData } from '../../selectors/app-data-selectors';
import { getSiteProperties } from '../site-properties/site-properties-selectors';
import { getTopology } from '../topology/topology-selectors';
import { getIsDemoMode } from '../instance-values/instance-values-selectors';

export const SET_APP_CONFIG = 'app-config/SET';

const setAppConfigAction = createAction(SET_APP_CONFIG);

export function setAppConfig({ sectionUrl, bundleName, fedopsAppName }) {
  return (dispatch, getState, { wixCodeApi, appParams }) => {
    dispatch(
      setAppConfigAction(
        getConfig({
          appParams,
          wixCodeApi,
          sectionUrl,
          state: getState(),
          bundleName,
          fedopsAppName,
        }),
      ),
    );
  };
}

function getConfig({ appParams, wixCodeApi, sectionUrl, state, bundleName, fedopsAppName }) {
  const isProduction = process.env.NODE_ENV === 'production';
  const { isEditor, isPreview } = getEnvironment(wixCodeApi);

  const appData = getAppData(state);
  const siteProperties = getSiteProperties(state);
  const topology = getTopology(state);
  const isDemoMode = getIsDemoMode(state);

  const developmentConfig = {
    isDevMode: !isProduction,
    debugEnabled: BLOG_APP_DEBUG,
    logBiToConsole: LOG_BI_TO_CONSOLE,
    logReduxActions: LOG_REDUX_ACTIONS,
    logPostEditorActions: LOG_BLOG_POST_EDITOR_ACTIONS,
  };
  const isSeo = wixCodeApi.seo.isInSEO();
  const isWP = WP_BLOGS.includes(appParams.instanceId);

  const { baseUrl, postPageSectionUrl } = topology;
  const isAmpEnabled = appData.ampEnabled;
  const ampBaseUrl = `${baseUrl}/amp`;
  const wpAmpBaseUrl = AMP_BASE_URLS[appParams.instanceId];

  const publisher =
    getJsonSchemaPublisher({
      isWP,
      imageHost: appParams.baseUrls.mediaImageHost,
      siteProperties,
      isAmp: false,
      isSchemaEnabled: appData.schemaEnabled,
    }) || {};

  const clientConfig = {
    bundleName,
    fedopsAppName,
    imageHost: appParams.baseUrls.mediaImageHost,
    videoHost: appParams.baseUrls.mediaVideoHost,
    instanceId: appParams.instanceId,
    duplexer: {
      url: appParams.baseUrls.duplexerUrl,
    },
    isInEditor: isEditor,
    isInPreview: isPreview,
    isWP,
    categoryPath: isWP ? 'category' : 'categories',
    useCategoryMenuLabelForMetadataTitle: isWP && !isSeo,
    ampBaseUrl: wpAmpBaseUrl || (isAmpEnabled && baseUrl && ampBaseUrl),
    sectionUrl: get(topology, 'sectionUrl', isDemoMode ? sectionUrl : ''),
    postPageSectionUrl,
    publisher,
    ...developmentConfig,
  };

  return clientConfig;
}

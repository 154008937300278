import { TAB_SCOPE_POST_SETTINGS } from '@wix/communities-blog-client-common/dist/src/constants/tabs';

export const SET_ACTIVE_TAB = 'activeTab/SET';

export const setActiveTab = scope => tab => ({
  type: SET_ACTIVE_TAB,
  payload: { [scope]: tab },
});

export const setActivePostSettingsTab = setActiveTab(TAB_SCOPE_POST_SETTINGS);

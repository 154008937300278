import { getVideoCount, getImageCount } from '../content-selectors';
import { CREATE_COMMENT_SUCCESS } from '../../store/comments/create-comment';

const handler = action => {
  const comment = action.payload;
  return {
    evid: 204,
    is_edited: 0,
    video_count: getVideoCount(comment.content),
    image_count: getImageCount(comment.content),
    comment_id: comment._id,
    post_id: comment.postId,
    eventMeta: {
      description: 'comment published',
    },
  };
};

export const uouCreateCommentSuccessEvent = { [CREATE_COMMENT_SUCCESS]: handler };
